import { SnackbarContext } from '../../Snack/Snack'
import { getConfig } from '../../config'
import { TEXT_LABELS } from '../consts'
import { WidgetMenuItemConfig, MenuType, WidgetCard, useGetWidgetData } from '@nike/cx-widget'
import { MetricWidget } from '@nike/cx-widget/dist/types-generated'
import { useContext } from 'react'

interface WidgetCardResolverProps {
  widget: MetricWidget
  accessToken: string
}

export const WidgetCardResolver = ({ widget, accessToken }: WidgetCardResolverProps) => {
  const { env } = getConfig()
  const { createSnack } = useContext(SnackbarContext)
  const { contacts, id, name, dataSource } = widget

  const {
    get,
    isFetching,
    errorMessage,
    data: widgetData,
  } = useGetWidgetData({
    accessToken,
    id: id || name,
    widget: { ...widget.dataSource, timezoneSupport: dataSource.timezoneSupport || false },
    env,
  })

  const widgetMenuConfig: WidgetMenuItemConfig[] = [
    {
      type: MenuType.SHARE,
    },
    {
      type: MenuType.EXPAND,
    },
    {
      type: MenuType.REFRESH,
      onClick: get,
    },
    {
      type: MenuType.LINK,
      additionalProps: {
        href: contacts.maintainerSlack,
      },
      content: TEXT_LABELS.slackBtn,
      withDivider: true,
    },
    ...(contacts.moreInfoURL
      ? [
          {
            type: MenuType.LINK,
            additionalProps: {
              href: contacts.moreInfoURL,
            },
            content: TEXT_LABELS.moreInfo,
          },
        ]
      : []),
  ]

  return (
    <WidgetCard
      widgetMenuConfig={widgetMenuConfig}
      widget={widget}
      isFetching={isFetching}
      errorMessage={errorMessage}
      widgetData={widgetData}
      getRefreshedWidgetData={get}
      createSnack={createSnack}
      isShareable
    />
  )
}
