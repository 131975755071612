import type { ReleaseNote } from '../../util/api/pc'
import { MetricWidget } from '@nike/cx-widget/dist/types-generated'
import moment from 'moment'

export const formattedReleaseDate = (releaseNote?: ReleaseNote) =>
  moment(releaseNote?.dateReleased).format('LL')

export const releaseNoteUrl = (releaseNote?: ReleaseNote) => {
  const { protocol, hostname, pathname } = window.location
  const port = window.location.port ? `:${window.location.port}` : ''
  const noteIdQuery = releaseNote ? `&noteId=${releaseNote.id}` : ''
  return `${protocol}//${hostname}${port}${pathname}?activeTab=release-notes${noteIdQuery}`
}

export const isNewReleaseNote = (dateReleased: string, releaseNotesLastSeen: string): boolean =>
  !releaseNotesLastSeen ||
  new Date(dateReleased).getTime() > new Date(releaseNotesLastSeen).getTime()

export const sortWidgets = (widgetA: MetricWidget, widgetB: MetricWidget): number => {
  const nameA = widgetA.name.toLowerCase()
  const nameB = widgetB.name.toLowerCase()

  return nameA > nameB ? 1 : -1
}

export const getVideoUrl = (videoId?: string) => {
  const { origin, pathname } = window.location
  const videoQuery = videoId ? `&videoId=${videoId}` : ''
  return `${origin}${pathname}?activeTab=videos${videoQuery}`
}
