import { Category } from '../../util/api/pc'
import styles from '../homePage.styl'
import { Chip, Label } from '@nike/eds'

interface CategoriesProps {
  categories: Category[]
}

export const Categories = ({ categories }: CategoriesProps): JSX.Element => (
  <div className={styles.tsChipsContainer}>
    <Label font='subtitle-1'>Categories:</Label>
    {categories.map((category) => (
      <Chip key={category.id} size='small' className={styles.edsChip}>
        <Label font='subtitle-2'>{category.name}</Label>
      </Chip>
    ))}
  </div>
)
