import { MappedEvent } from './Calendar'
import styles from './calendar.module.styl'
import { formatEventTime } from './utils'
import { Button, Text } from '@nike/eds'
import { Notification } from '@nike/nike-design-system-icons'
import { office365 } from 'calendar-link'
import classnames from 'classnames'

interface UpcomingEventsProps {
  events: MappedEvent[]
}

export const UpcomingEvents = ({ events }: UpcomingEventsProps) => (
  <div className={classnames(styles.eventsList, styles.upcomingEvents)}>
    <div className={styles.upcomingEventsTitle}>
      <Text font='title-5'>Upcoming events:</Text>
      {/* this button doesn't do anything yet */}
      <Button variant='secondary' beforeSlot={<Notification />}>
        Subscribe this calendar
      </Button>
    </div>

    {events
      .sort((a, b) => a.start.getTime() - b.start.getTime())
      .slice(0, 5)
      .map((event) => {
        const outlookEvent = {
          title: event.title,
          description: event.description,
          start: event.start,
          end: event.end,
          location: event?.virtualLocation || event?.physicalLocation || '',
        }
        const outlookUrl = office365(outlookEvent)
        return (
          <div className={styles.eventItem} key={event.id}>
            <div className={styles.eventHeader}>
              <div className={styles.eventHeaderDate}>
                <Text font='body-3'>
                  {event.start.toLocaleDateString('default', { month: 'short' })}
                </Text>
                <Text font='title-4' color='brand-orange'>
                  {event.start.getDate()}
                </Text>
              </div>
              <Text font='title-6' className='eds-flex eds-flex--align-items-center'>
                {event.title}
              </Text>
            </div>
            <Text font='body-3'>{formatEventTime(event.start, event.end)}</Text>
            <Text font='body-3'>{event.description}</Text>

            {event.physicalLocation && (
              <Text font='body-3' color='secondary'>
                {event.physicalLocation}
              </Text>
            )}
            {event?.virtualLocation && (
              <Button<'a'> as='a' href={outlookUrl} target='_blank' variant='secondary'>
                Add to my calendar
              </Button>
            )}
          </div>
        )
      })}
  </div>
)
