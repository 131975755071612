// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FmiD8ZCVybcQxM2UsAiU {
  display: flex;
  justify-content: space-between;
}
.FmiD8ZCVybcQxM2UsAiU,
.eds--dark .FmiD8ZCVybcQxM2UsAiU {
  background-color: var(--eds-color-background-default);
}
.yvVdF2D3glneaZiTpPpH {
  width: 75%;
}
@media (min-width: 2000px) {
  .yvVdF2D3glneaZiTpPpH {
    width: 100%;
  }
}
.skLCTRjdEtQpAg5KE49g {
  margin: 0;
  margin-bottom: 6px;
}
.skLCTRjdEtQpAg5KE49g a:hover {
  text-decoration: underline;
  text-underline-offset: 5px;
}
.Fnfv1Buxxh9omGe3IXHk {
  color: var(--eds-color-text-secondary);
  margin: 0;
}
.UBN7GSum_g2i0XwUvIAo {
  margin-top: 16px;
}
.UBN7GSum_g2i0XwUvIAo ._8uOWsANr2q2IQesJRGM {
  margin-right: 10px;
}
.UBN7GSum_g2i0XwUvIAo span {
  color: var(--eds-color-grey-1, #757575);
}
.wwK8GyMbGkwNUZiy0Zx3 button {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.wwK8GyMbGkwNUZiy0Zx3 button svg {
  color: var(--eds-color-text-link, #0861b5);
}
.qhqVzJ22Zi7zzQqhHVhC {
  color: var(--eds-color-white, #fff);
}
`, "",{"version":3,"sources":["webpack://./src/EssentialCard/essentialCard.styl"],"names":[],"mappings":"AAEA;EACI,aAAQ;EACR,8BAAgB;AADpB;AAEI;;EACE,qDAAiB;AACvB;AAAE;EACE,UAAM;AAEV;AAD6B;EAAA;IACvB,WAAM;EAIV;AACF;AAJE;EACE,SAAO;EACP,kBAAc;AAMlB;AAJM;EACE,0BAAgB;EAChB,0BAAsB;AAM9B;AALE;EACE,sCAAM;EACN,SAAO;AAOX;AANE;EACE,gBAAW;AAQf;AAPI;EACE,kBAAa;AASnB;AARI;EACE,uCAAM;AAUZ;AARI;EACE,YAAO;EACP,WAAM;EACN,aAAQ;EACR,uBAAgB;EAChB,mBAAY;EACZ,UAAQ;AAUd;AATM;EACE,0CAAM;AAWd;AAVE;EACE,mCAAM;AAYV","sourcesContent":["@require '../styles/variables'\n\n.essentialCard\n    display flex\n    justify-content space-between\n    &, :global(.eds--dark) &\n      background-color var(--eds-color-background-default)\n  .content\n    width 75%\n    @media (min-width: 2000px)\n      width 100%\n  .name\n    margin 0\n    margin-bottom 6px\n    a\n      &:hover\n        text-decoration underline\n        text-underline-offset 5px\n  .description\n    color var(--eds-color-text-secondary)\n    margin 0\n  .health\n    margin-top 16px\n    .statusIcon\n      margin-right 10px\n    span\n      color eds-color-grey-1\n  .linkContainer\n    button\n      height 32px\n      width 32px\n      display flex\n      justify-content center\n      align-items center\n      padding 0\n      svg\n        color eds-color-text-link\n  .tooltip\n    color eds-color-white\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"essentialCard": `FmiD8ZCVybcQxM2UsAiU`,
	"content": `yvVdF2D3glneaZiTpPpH`,
	"name": `skLCTRjdEtQpAg5KE49g`,
	"description": `Fnfv1Buxxh9omGe3IXHk`,
	"health": `UBN7GSum_g2i0XwUvIAo`,
	"statusIcon": `_8uOWsANr2q2IQesJRGM`,
	"linkContainer": `wwK8GyMbGkwNUZiy0Zx3`,
	"tooltip": `qhqVzJ22Zi7zzQqhHVhC`
};
export default ___CSS_LOADER_EXPORT___;
