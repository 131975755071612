import { MarkdownComponents } from '../components/MarkdownComponents'
import { Announcement } from '../util/api/pc'
import styles from './announcements.styl'
import { dismissAnnouncement, checkAnnouncements } from './cache'
import { IconButton, StatusIcon } from '@nike/eds'
import classnames from 'classnames'
import { useState, useCallback, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'

export interface AnnouncementsProps {
  techSolutionId: string
  announcements: Announcement[]
}

export const Announcements = ({
  announcements = [],
  techSolutionId,
}: AnnouncementsProps): JSX.Element | null => {
  const [visibleAnnouncements, setVisibleAnnouncements] = useState<Announcement[]>(() =>
    checkAnnouncements(announcements, techSolutionId)
  )

  const deleteAnnouncement = useCallback(
    (id: string) => () => {
      void dismissAnnouncement(id, techSolutionId)
      setVisibleAnnouncements(checkAnnouncements(announcements, techSolutionId))
    },
    [techSolutionId, announcements]
  )

  useEffect(() => {
    setVisibleAnnouncements(checkAnnouncements(announcements, techSolutionId))
  }, [announcements])

  if (!visibleAnnouncements.length) return null

  return (
    <>
      {visibleAnnouncements.map((announcement) => (
        <div
          key={announcement.id}
          className={classnames(styles.announcement, {
            [styles.error]: announcement.type === 'error',
            [styles.success]: announcement.type === 'success',
            [styles.warning]: announcement.type === 'warning',
          })}
        >
          <StatusIcon
            className={classnames(styles.icon, {
              [styles.infoIcon]: announcement.type === 'info' || !announcement.type,
            })}
            status={announcement.type ? announcement.type : 'info'}
            statusIconSize='m'
          />
          <div className='eds-flex--grow-1'>
            <ReactMarkdown components={MarkdownComponents}>{announcement.markdown}</ReactMarkdown>
          </div>
          <div>
            <IconButton
              data-testid='dismiss-announcement'
              label='Dismiss Announcement'
              size='small'
              variant='ghost'
              onClick={deleteAnnouncement(announcement.id)}
              icon='Close'
            />
          </div>
        </div>
      ))}
    </>
  )
}
