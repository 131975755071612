import { MappedEvent } from './Calendar'
import { Pagination } from './Pagination'
import styles from './calendar.module.styl'
import { formatEventTime } from './utils'
import { Button, Text } from '@nike/eds'
import { BrightnessNone } from '@nike/nike-design-system-icons'
import { office365 } from 'calendar-link'
import { useState } from 'react'

interface EventsListProps {
  events: MappedEvent[]
}

const PAGE_SIZE = 3

export const EventsList = ({ events }: EventsListProps) => {
  const [currentPage, setCurrentPage] = useState(0)
  const pagesCount = Math.ceil(events.length / PAGE_SIZE)
  const startIndex = currentPage * PAGE_SIZE

  const handleNext = () => setCurrentPage((prevState) => prevState + 1)
  const handlePrev = () => setCurrentPage((prevState) => prevState - 1)

  const eventsToDisplay = events.slice(startIndex, startIndex + PAGE_SIZE)

  if (events.length) {
    return (
      <>
        <div className={styles.eventsList}>
          {eventsToDisplay.map((event) => {
            const outlookEvent = {
              title: event.title,
              description: event.description,
              start: event.start,
              end: event.end,
              location: event?.virtualLocation || event?.physicalLocation || '',
            }
            const outlookUrl = office365(outlookEvent)
            return (
              <div className={styles.eventItem} key={event.title}>
                <div className={styles.eventHeader}>
                  <div className={styles.eventHeaderDate}>
                    <Text font='body-3'>
                      {event.start.toLocaleDateString('default', { month: 'short' })}
                    </Text>
                    <Text font='title-4' color='brand-orange'>
                      {event.start.getDate()}
                    </Text>
                  </div>
                  <Text font='title-6' className='eds-flex eds-flex--align-items-center'>
                    {event.title}
                  </Text>
                </div>
                <Text font='body-3'>{formatEventTime(event.start, event.end)}</Text>
                {event.physicalLocation && (
                  <Text font='body-3' color='secondary'>
                    {event.physicalLocation}
                  </Text>
                )}
                {event?.virtualLocation && (
                  <Button<'a'>
                    as='a'
                    href={outlookUrl}
                    target='_blank'
                    variant='secondary'
                    className='eds-spacing--mt-8'
                  >
                    Add to my calendar
                  </Button>
                )}
              </div>
            )
          })}
        </div>
        {pagesCount > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={pagesCount}
            handleNext={handleNext}
            handlePrev={handlePrev}
          />
        )}
      </>
    )
  }

  return (
    <div className={styles.noEventsMessage}>
      <BrightnessNone color='grey' />
      <Text font='body-3'>There are no events on this day</Text>
    </div>
  )
}
