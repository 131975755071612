import { Link } from '../../components/Link'
import { getConfig } from '../../config'
import { Platform, getPlatformData } from '../../util/api/pc'
import styles from '../homePage.styl'
import { Chip, Label, Spinner } from '@nike/eds'
import { useEffect, useState } from 'react'

export interface TSPlatform {
  name: string
  slug: string
}

interface TSPlatformsProps {
  accessToken: string
  techSolutionId: string
  navigate?: (href: string) => void
}
export const TSPlatforms = ({
  accessToken,
  techSolutionId,
  navigate,
}: TSPlatformsProps): JSX.Element => {
  const config = getConfig()
  const [platforms, setPlatforms] = useState<TSPlatform[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    async function fetchData() {
      try {
        setPlatforms([])
        setLoading(true)
        const { platforms: data } = await getPlatformData({
          platformConsoleApiUrl: config.platformConsoleApiUrl,
          accessToken,
        })

        if (data) {
          const arr: TSPlatform[] = []
          data.forEach((platform: Platform) => {
            const match = platform.techSolutions?.find(
              ({ id }: { id: string }) => id === techSolutionId
            )
            if (match) {
              arr.push({ name: platform.name, slug: platform.slug })
            }
          })
          setPlatforms(arr)
        }
        setLoading(false)
      } catch (error) {
        console.error(error)
      }
    }
    void fetchData()
  }, [techSolutionId, accessToken])

  return (
    <div className={styles.tsChipsContainer}>
      <Label font='subtitle-1'>Platform Group:</Label>
      {loading && <Spinner className='eds-spacing--ml-8' size='medium' />}
      {platforms.map(
        ({ name, slug }: TSPlatform, i): JSX.Element => (
          <Chip key={`${slug}-${i}`} className={styles.edsChip}>
            <Label font='subtitle-2'>
              <Link navigate={navigate} className={styles.link} href={`/platforms/${slug}`}>
                {name}
              </Link>
            </Label>
          </Chip>
        )
      )}
    </div>
  )
}
