import { Video } from '../../util/api/pc'
import styles from './../homePage.styl'
import { Button, ButtonGroup, Modal } from '@nike/eds'
import { Link } from '@nike/nike-design-system-icons'

interface VideoModalProps {
  video: Video
  closeModal: () => void
  copyVideoLink: (id: string) => void
}

export const VideoModal = ({ closeModal, video, copyVideoLink }: VideoModalProps) => (
  <Modal
    isOpen
    onDismiss={closeModal}
    headerSlot={<>Video: {video.name}</>}
    footerSlot={
      <ButtonGroup>
        <Button size='small' onClick={closeModal}>
          Close
        </Button>
        <Button
          size='small'
          variant='secondary'
          beforeSlot={<Link />}
          onClick={() => copyVideoLink(video.id)}
        >
          Copy Link
        </Button>
      </ButtonGroup>
    }
  >
    <div className={styles.videoModal}>
      <video className={styles.video} src={video.url} controls data-testid='video-preview' />
    </div>
  </Modal>
)
