// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SGtaxCOxXXGF2XHYMBY6 {
  font: var(--eds-type-body-2);
}
.d2sebdXhnMXHvN4iwczP {
  cursor: pointer;
}
.d2sebdXhnMXHvN4iwczP.qUEP0PpSUD9oU4CxyozX {
  cursor: default;
}
.d2sebdXhnMXHvN4iwczP .JbvXYQ7tK8mYcJLachLL {
  display: flex;
  justify-content: center;
  width: 150px;
  margin: 4px;
  color: var(--eds-color-white, #fff);
}
.fa9s_wUE17PZvxlaGjZb {
  color: var(--eds-color-red-40);
}
.eds-tooltip[data-popper-placement="bottom"] {
  margin-top: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/FavoriteStatus/favoriteStatus.styl"],"names":[],"mappings":"AAEA;EACC,4BAAM;AADP;AAGA;EACE,eAAO;AADT;AAEE;EACE,eAAO;AAAX;AAEE;EACE,aAAS;EACT,uBAAiB;EACjB,YAAM;EACN,WAAO;EACP,mCAAM;AAAV;AAEA;EACE,8BAAM;AAAR;AAGE;EACE,eAAW;AADf","sourcesContent":["@require '../styles/variables'\n\n.mfe-main \n font: var(--eds-type-body-2)\n\n.favoriteButton\n  cursor pointer\n  &.disabled\n    cursor default\n\n  .tooltip\n    display: flex\n    justify-content: center\n    width 150px\n    margin 4px\n    color eds-color-white\n\n.favoriteActive\n  color var(--eds-color-red-40)\n\n:global\n  .eds-tooltip[data-popper-placement=\"bottom\"]\n    margin-top 2px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mfe-main": `SGtaxCOxXXGF2XHYMBY6`,
	"favoriteButton": `d2sebdXhnMXHvN4iwczP`,
	"disabled": `qUEP0PpSUD9oU4CxyozX`,
	"tooltip": `JbvXYQ7tK8mYcJLachLL`,
	"favoriteActive": `fa9s_wUE17PZvxlaGjZb`
};
export default ___CSS_LOADER_EXPORT___;
