import { Fields } from '../../AccessRequest/custom-form/fields/types'
import {
  createSubscriptionMutation,
  deleteSubscriptionMutation,
  prepareAdGroupsMutation,
  requestAccessMutation,
  updateUserFavoritesMutation,
  updateUserRecentsMutation,
  updateUserReleaseNotesLastSeenMutation,
} from '../graphql/mutations'
import {
  Query,
  getReleaseNotesQuery,
  getTechSolutionQuery,
  getUserAccessStatusQuery,
  getUserQuery,
  platformsData,
  subscriptionForUser,
} from '../graphql/queries'
import { client } from './client'
import { MetricWidget } from '@nike/cx-widget/dist/types-generated'

export type RequestStatus =
  | 'notConfigured'
  | 'available'
  | 'pending'
  | 'declined'
  | 'approved'
  | 'failed'

export interface FavoriteInput {
  id: string
  type: string
}

interface Favorite extends FavoriteInput {
  name: string
  slug: string
}

export interface User {
  favorites: Favorite[] | null
  newReleaseNotesCount: number
  releaseNotesLastSeen: string
}

interface UserResponse {
  user: User | null
}

export type SupportService = 'SLACK' | 'JIRA' | 'SNOW'

export interface ReleaseNote {
  id: string
  link: string | null
  title: string
  markdown: string
  dateReleased: string
}

export interface TechSolutionWithReleaseNotes {
  name: string
  releaseNotes: ReleaseNote[]
}

export interface ReleaseNotesResponse {
  techSolution: TechSolutionWithReleaseNotes
}

export type AnnouncementType = 'error' | 'info' | 'success' | 'warning'

export type UserAccessType = 'jira' | 'idlocker' | 'snow'

export interface Announcement {
  id: string
  markdown: string
  type?: AnnouncementType | null
  releasedOn?: string
}

export type IntegratedPath = string | null

export type AccessType = 'INTEGRATED' | 'DIVESTED' | 'LINK' | 'STEPS'

export type SnowFormType = 'link' | 'cse'

type SnowField = {
  value: string
  formType: SnowFormType
}

export type IDLockerField = {
  adGroupValue?: string | null
  role?: string | null
  value: string
}

type JiraField = {
  enableJiraCustomForm: boolean
  customForm: string
  customFormDescription: string
}

export type IDLockerSetting = {
  type: 'idlocker'
  fields: IDLockerField[]
}

export type SnowSetting = {
  type: 'snow'
  fields: SnowField[]
}

export type JiraSetting = {
  type: 'jira'
  fields: JiraField[]
}

export type UserAccessSetting =
  | {
      type: 'okta'
      fields: null
    }
  | IDLockerSetting
  | SnowSetting
  | JiraSetting

export interface IDLocker {
  roles: Array<{ adGroups: Array<string>; role: string }>
}

export interface JiraCustomForm {
  enable: boolean
  data?: string
  description?: string
}

export interface Jira {
  description?: string | null
  projectKey: string
  customForm?: JiraCustomForm | null
}

export interface Snow {
  formType: SnowFormType
  externalLink?: string | null
  customForm?: JiraCustomForm | null
}

export interface UserAccessSettingsV2 {
  snow?: Snow | null
  jira?: Jira | null
  idLocker?: IDLocker | null
}

export type HealthStatus = 'pass' | 'warn' | 'fail'

interface Health {
  status: HealthStatus | null
  updatedOn: number | null
}

type ContactType = 'GITHUB' | 'SLACK' | 'PAGERDUTY'

export interface Contact {
  type: ContactType
  link: {
    text: string
    url: string
  }
}

export interface TrainingResource {
  name: string
  description: string
  url: string
}

export interface Template {
  name: string
  description: string
}

export interface DevPortalTemplate {
  projectName: string
  description: string
}

export interface RelatedTechSolution {
  id: string
  name: string
  description: string
  slug: string
  integratedPath: IntegratedPath
  accessType: AccessType
  health: Health
}

export type Video = {
  contentType: string
  createdBy: string
  id: string
  name: string
  size: number
  team: string
  updatedBy: string
  url: string
}

export enum EventType {
  MEETING = 'MEETING',
  OTHER = 'OTHER',
  RELEASE = 'RELEASE',
  TRAINING = 'TRAINING',
}

export type Calendar = {
  events: CalendarEvent[] | null
  isEnabled: boolean | null
}

export type CalendarEvent = {
  description: string
  end?: string
  id: string
  location?: string
  start: string
  title: string
  type: EventType
  url?: string
}

export type Category = {
  id: string
  name: string
}

export interface TechSolution extends RelatedTechSolution {
  title: string
  url: string
  devPortalProjectName: string
  announcements: Announcement[]
  accessType: AccessType
  userAccessSettingsV2: UserAccessSettingsV2 | null
  owningTeam: string | null
  owningTeamName: string | null
  roadmapLink: string | null
  contacts: Contact[] | []
  supportService: SupportService | null
  slack: string | null
  lifecycle: TechSolutionLifecycle | null
  trainingResources: TrainingResource[] | null
  templates: string[] | null
  releaseNotes: ReleaseNote[] | null
  metricWidgets: MetricWidget[] | null
  uptime: Uptime
  videos: Video[] | null
  calendar: Calendar | null
  categories: Category[] | null
}

enum TechSolutionLifecycleStatusType {
  PLAN = 'PLAN',
  PILOT = 'PILOT',
  PRODUCTION = 'PRODUCTION',
  SUNSET = 'SUNSET',
  RETIRED = 'RETIRED',
}

interface TechSolutionLifecycleState {
  status: TechSolutionLifecycleStatusType
}

interface TechSolutionLifecycle {
  status: TechSolutionLifecycleStatusType
  details: [TechSolutionLifecycleState]
}

export type Status = 'pass' | 'warn' | 'fail' | 'undetermined' | 'maintenance'

export interface RegionalHealth {
  status: Status
  updatedOn: number
  name: string
  region: string
}

export type UptimeRegion = {
  region: string
  value: number
}

export type UptimeQuarter = {
  quarter: number
  regions: UptimeRegion[]
  year: number
}

export type Uptime = {
  current?: UptimeRegion[]
  quarters?: UptimeQuarter[]
}

export interface TechSolutionResponse {
  techSolution: TechSolution
  regionalHealth: RegionalHealth[]
  relatedTechSolutions: RelatedTechSolution[]
}

export interface ConsoleApiError {
  errorType: string
  message: string
}

interface ConsolePlatformResponse<T> {
  errors?: ConsoleApiError[]
  data: T
}

interface GetConsolePlatformParams {
  platformConsoleApiUrl: string
  accessToken: string
  techSolutionId: string
}

export interface RecentInput {
  id: string
  type: string
}

interface UpdateUserRecentsConsolePlatformParams {
  platformConsoleApiUrl: string
  accessToken: string
  input: RecentInput
}

interface UpdateFavConsolePlatformParams {
  platformConsoleApiUrl: string
  accessToken: string
  input: {
    input: FavoriteInput
    action: string
  }
}

interface UpdateUserRecentsResponse {
  updateUserRecents: {
    id: string
  }
}

export interface ReleaseNotesLastSeenInput {
  techSolutionId: string
}

export interface UpdateReleaseNotesLastSeenParams {
  platformConsoleApiUrl: string
  accessToken: string
  input: ReleaseNotesLastSeenInput
}

export interface UpdateReleaseNotesLastSeenResponse {
  updateUserReleaseNotesLastSeen: {
    releaseNotesLastSeen: string
  }
}

interface UpdateUserFavoritesResponse {
  updateUserFavorites: {
    message: string
    success: boolean
  }
}

interface StatusResponse {
  status: RequestStatus
  jira: RequestStatus
  idLocker: RequestStatus
  snow: RequestStatus
}

interface GetUserAccessStatusForTechSolutionResponse {
  getUserAccessStatusForTechSolution: StatusResponse
}

interface PutUserAccessStatusForTechSolutionResponse {
  putUserAccessRequestForTechSolution: StatusResponse
}

interface GetUserAccessStatus {
  userAccessStatus?: RequestStatus
  idLocker?: RequestStatus
  jira?: RequestStatus
  snow?: RequestStatus
  errors?: ConsoleApiError[]
}

interface UserAccessParams extends GetConsolePlatformParams {
  role?: string
  customForm?: Fields
}

interface PrepareAdGroupsResponse {
  prepareADGroupDetailsForAccessRequest: [role: string]
}

interface PrepareAdGroupsForRequest {
  roles: string[]
  errors?: ConsoleApiError[]
}

interface GetConsolePlatformData {
  <T, I = void>(
    platformConsoleApiUrl: string,
    query: Query<I>,
    accessToken: string,
    withErrors: true
  ): Promise<ConsolePlatformResponse<T>>
  <T, I = void>(
    platformConsoleApiUrl: string,
    query: Query<I>,
    accessToken: string,
    withErrors?: false
  ): Promise<T>
}

const getConsolePlatformData: GetConsolePlatformData = async <T, I = void>(
  platformConsoleApiUrl: string,
  query: Query<I>,
  accessToken: string,
  withErrors = false
) => {
  const { data, errors } = await client.post<Query<I>, ConsolePlatformResponse<T>>(
    platformConsoleApiUrl,
    query,
    {
      Authorization: accessToken.toString(),
    }
  )

  if (errors) {
    console.error(` Error fetching console api data: ${JSON.stringify(errors)}`)
  }

  if (withErrors) {
    return { data, errors }
  }

  return data
}

export const getTechSolution = async ({
  platformConsoleApiUrl,
  accessToken,
  techSolutionId,
}: GetConsolePlatformParams): Promise<TechSolutionResponse> =>
  await getConsolePlatformData<TechSolutionResponse>(
    platformConsoleApiUrl,
    getTechSolutionQuery(techSolutionId),
    accessToken
  )

export const getReleaseNotes = async ({
  platformConsoleApiUrl,
  accessToken,
  techSolutionId,
}: GetConsolePlatformParams): Promise<ReleaseNotesResponse> =>
  await getConsolePlatformData<ReleaseNotesResponse>(
    platformConsoleApiUrl,
    getReleaseNotesQuery(techSolutionId),
    accessToken
  )

export const getUserAccessStatus = async ({
  platformConsoleApiUrl,
  accessToken,
  techSolutionId,
  role,
}: UserAccessParams): Promise<GetUserAccessStatus> => {
  const { data, errors }: ConsolePlatformResponse<GetUserAccessStatusForTechSolutionResponse> =
    await getConsolePlatformData<GetUserAccessStatusForTechSolutionResponse>(
      platformConsoleApiUrl,
      role
        ? getUserAccessStatusQuery(techSolutionId, role)
        : getUserAccessStatusQuery(techSolutionId),
      accessToken,
      true
    )
  return {
    userAccessStatus: data?.getUserAccessStatusForTechSolution?.status,
    idLocker: data?.getUserAccessStatusForTechSolution?.idLocker,
    jira: data?.getUserAccessStatusForTechSolution?.jira,
    snow: data?.getUserAccessStatusForTechSolution?.snow,
    errors,
  }
}

export const putUserAccessRequest = async ({
  platformConsoleApiUrl,
  accessToken,
  techSolutionId,
  role,
  customForm,
}: UserAccessParams): Promise<GetUserAccessStatus> => {
  const { data, errors }: ConsolePlatformResponse<PutUserAccessStatusForTechSolutionResponse> =
    await getConsolePlatformData<PutUserAccessStatusForTechSolutionResponse>(
      platformConsoleApiUrl,
      role
        ? requestAccessMutation({ techSolutionId, role, customForm })
        : customForm
        ? requestAccessMutation({ techSolutionId, customForm })
        : requestAccessMutation({ techSolutionId }),
      accessToken,
      true
    )
  return {
    userAccessStatus: data?.putUserAccessRequestForTechSolution?.status,
    idLocker: data?.putUserAccessRequestForTechSolution?.idLocker,
    jira: data?.putUserAccessRequestForTechSolution?.jira,
    snow: data?.putUserAccessRequestForTechSolution?.snow,
    errors,
  }
}

export const prepareAdGroupsForRequest = async ({
  platformConsoleApiUrl,
  accessToken,
  techSolutionId,
}: GetConsolePlatformParams): Promise<PrepareAdGroupsForRequest> => {
  const { data, errors }: ConsolePlatformResponse<PrepareAdGroupsResponse> =
    await getConsolePlatformData<PrepareAdGroupsResponse>(
      platformConsoleApiUrl,
      prepareAdGroupsMutation(techSolutionId),
      accessToken,
      true
    )

  return {
    roles: data?.prepareADGroupDetailsForAccessRequest,
    errors,
  }
}

export const updateUserRecents = async ({
  platformConsoleApiUrl,
  accessToken,
  input,
}: UpdateUserRecentsConsolePlatformParams): Promise<UpdateUserRecentsResponse> =>
  await getConsolePlatformData<UpdateUserRecentsResponse, RecentInput>(
    platformConsoleApiUrl,
    updateUserRecentsMutation(input),
    accessToken
  )

export const updateUserFavorites = async ({
  platformConsoleApiUrl,
  accessToken,
  input,
}: UpdateFavConsolePlatformParams): Promise<UpdateUserFavoritesResponse> =>
  await getConsolePlatformData<UpdateUserFavoritesResponse, FavoriteInput>(
    platformConsoleApiUrl,
    updateUserFavoritesMutation(input),
    accessToken
  )

export const updateUserReleaseNotesLastSeen = async ({
  platformConsoleApiUrl,
  accessToken,
  input,
}: UpdateReleaseNotesLastSeenParams): Promise<UpdateReleaseNotesLastSeenResponse> =>
  await getConsolePlatformData<UpdateReleaseNotesLastSeenResponse, ReleaseNotesLastSeenInput>(
    platformConsoleApiUrl,
    updateUserReleaseNotesLastSeenMutation(input),
    accessToken
  )

export const getUser = async ({
  platformConsoleApiUrl,
  accessToken,
  techSolutionId,
}: GetConsolePlatformParams): Promise<UserResponse> =>
  await getConsolePlatformData<UserResponse>(
    platformConsoleApiUrl,
    getUserQuery(techSolutionId),
    accessToken
  )

interface GetSubscriptionResponse {
  subscriptionForUser: null | {
    email: string
    techSolutionId: string
  }
}

export const getSubscription = async ({
  platformConsoleApiUrl,
  accessToken,
  techSolutionId,
}: GetConsolePlatformParams): Promise<GetSubscriptionResponse> =>
  await getConsolePlatformData<GetSubscriptionResponse>(
    platformConsoleApiUrl,
    subscriptionForUser(techSolutionId),
    accessToken
  )

interface CreateSubscriptionResponse {
  createSubscription: null | {
    email: string
    techSolutionId: string
  }
}

export const createSubscription = async ({
  platformConsoleApiUrl,
  accessToken,
  techSolutionId,
}: GetConsolePlatformParams): Promise<CreateSubscriptionResponse> =>
  await getConsolePlatformData<CreateSubscriptionResponse>(
    platformConsoleApiUrl,
    createSubscriptionMutation(techSolutionId),
    accessToken
  )

interface DeleteSubscriptionResponse {
  deleteSubscription: string
}

export const deleteSubscription = async ({
  platformConsoleApiUrl,
  accessToken,
  techSolutionId,
}: GetConsolePlatformParams): Promise<DeleteSubscriptionResponse> =>
  await getConsolePlatformData<DeleteSubscriptionResponse>(
    platformConsoleApiUrl,
    deleteSubscriptionMutation(techSolutionId),
    accessToken
  )

interface TSiD {
  id: string
}
export interface Platform {
  slug: string
  name: string
  techSolutions: TSiD[]
}

export const getPlatformData = async ({
  platformConsoleApiUrl,
  accessToken,
}: {
  platformConsoleApiUrl: string
  accessToken: string
}): Promise<{
  platforms: Platform[]
}> => await getConsolePlatformData(platformConsoleApiUrl, platformsData(), accessToken)

/* --- copied form PC API generated types --- */
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type GithubWidgetDataQueryVariables = Exact<{
  dataSourceURL: string
}>
export type GithubWidgetDataQuery = { githubWidgetData?: { data: string } | null }

export type WidgetFilters = {
  default?: string
  description?: string
  enum?: string[]
  maxDate?: string
  minDate?: string
  name: string
  pattern?: string
  type: string
}
