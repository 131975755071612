import { AccessResolver } from '../AccessRequest'
import { Announcements } from '../Announcements'
import { FavoriteStatus } from '../FavoriteStatus'
import { HealthStatus } from '../HealthStatus'
import { Snacks } from '../Snack/Snack'
import { SubscribeButton } from '../SubscribeButton'
import { ErrorMessage } from '../components/ErrorMessage'
import { Link } from '../components/Link'
import { AppConfig, getConfig, setConfig, EnvType } from '../config'
import { authDAMSession } from '../util/api/authDamSession'
import { apolloClient, setAccessToken } from '../util/api/client'
import { getTemplateProjectFromDevPortal } from '../util/api/devportal'
import {
  TechSolution,
  TechSolutionResponse,
  Template,
  DevPortalTemplate,
  getTechSolution,
  getUser,
  User,
  updateUserReleaseNotesLastSeen,
} from '../util/api/pc'
import { getTeams } from '../util/api/teams'
import { Categories } from './actionComponents/Categories'
import { GetSupport } from './actionComponents/GetSupport'
import { LifeCycle } from './actionComponents/LifeCycle'
import { TSPlatforms } from './actionComponents/TSPlatforms'
import styles from './homePage.styl'
import { TabsGroup } from './tabComponents/TabsGroup'
import { isAdmin, setAnnouncementIds } from './util'
import { ApolloProvider } from '@apollo/client'
import { Button, ButtonGroup, Text, Skeleton } from '@nike/eds'
import jwt, { JwtPayload } from 'jwt-decode'
import { useEffect, useState } from 'react'

export interface HomePageProps {
  techSolutionId: string
  accessToken: string
  navigate?: (href: string) => void
  env?: EnvType
  showCalendarPoc?: boolean
}

export type TechSolutionData = Pick<
  TechSolutionResponse,
  'regionalHealth' | 'relatedTechSolutions'
> &
  TechSolution

export const HomePage = ({
  techSolutionId,
  accessToken,
  navigate,
  env = 'dev',
  showCalendarPoc = false,
}: HomePageProps): JSX.Element => {
  setConfig(env)
  const config: AppConfig = getConfig()
  const existingTechSolutionData: TechSolutionData = getTechSolutionCache(techSolutionId)
  const [techSolutionData, setTechSolutionData] =
    useState<TechSolutionData>(existingTechSolutionData)
  const [userData, setUserData] = useState<User | null>()
  const [templates, setTemplates] = useState<Template[]>([])

  const [showAdmin, setShowAdmin] = useState(false)
  const [loading, setLoading] = useState<boolean>(!existingTechSolutionData)
  const user = jwt<JwtPayload>(accessToken)

  useEffect(() => {
    setAccessToken(accessToken)
    async function fetchData() {
      const cachedTechSolutionData = getTechSolutionCache(techSolutionId)
      if (cachedTechSolutionData?.name) {
        setTechSolutionData(cachedTechSolutionData)
      } else {
        setLoading(true)
        setTechSolutionData({} as TechSolutionData)
      }
      try {
        await authDAMSession()
        const { techSolution, relatedTechSolutions, regionalHealth } = await getTechSolution({
          platformConsoleApiUrl: config.platformConsoleApiUrl,
          techSolutionId,
          accessToken,
        })

        if (techSolution?.templates?.length) {
          // Set up as separate piece of state
          // so page renders async to this call
          void fetchTemplates(techSolution.templates)
        }

        setTechSolutionData({ ...techSolution, relatedTechSolutions, regionalHealth })
        setTechSolutionCache(techSolutionId, {
          ...techSolution,
          relatedTechSolutions,
          regionalHealth,
        })
        document.title = `${techSolution.name} - Platform Console`
        const teams = await getTeams(config.teamsApiUrl, accessToken)
        setShowAdmin(
          isAdmin(teams, config.platformConsoleTeamId, user.sub, techSolution?.owningTeam)
        )

        const { user: fetchedUserData } = await getUser({
          platformConsoleApiUrl: config.platformConsoleApiUrl,
          techSolutionId,
          accessToken,
        })
        setUserData(fetchedUserData)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    void fetchData()
  }, [techSolutionId, accessToken])

  async function fetchTemplates(techSolutionTemplates: string[]) {
    const results: (DevPortalTemplate | undefined)[] = await Promise.all(
      techSolutionTemplates.map(getTemplateProjectFromDevPortal)
    )
    setTemplates(
      results.filter(Boolean).map((result) => ({
        name: (result as DevPortalTemplate).projectName,
        description: (result as DevPortalTemplate).description,
      }))
    )
  }

  if (loading && !techSolutionData?.name) {
    return <HomePageSkeleton />
  }

  if (!techSolutionData) {
    return <ErrorMessage name='platformconsole' slackURL={config.consoleSlackChannel} />
  }

  const {
    announcements,
    name,
    description,
    title,
    slug,
    roadmapLink,
    devPortalProjectName,
    relatedTechSolutions,
    regionalHealth,
    owningTeamName,
    owningTeam,
    contacts,
    supportService,
    slack,
    uptime,
    calendar,
  } = techSolutionData

  const contactsFiltered = contacts?.filter(({ link }) => link.url)

  const releaseNotes = techSolutionData.releaseNotes
    ? techSolutionData.releaseNotes.sort((a, b) =>
        b.dateReleased?.localeCompare(a.dateReleased, 'en', { sensitivity: 'base' })
      )
    : null
  const updateUserReleaseNotesLastSeenArgs = {
    platformConsoleApiUrl: getConfig().platformConsoleApiUrl,
    input: {
      techSolutionId,
    },
    accessToken: accessToken,
  }

  const apolloClientObj = apolloClient(accessToken, config.platformConsoleApiUrl)

  return (
    <ApolloProvider client={apolloClientObj}>
      <Snacks>
        <div className={styles.pageWrapper}>
          <Announcements
            techSolutionId={techSolutionId}
            announcements={setAnnouncementIds(announcements)}
          />
          <div className={styles.homePage}>
            <Text<'span'> className={styles.subtitle} as='span' font='subtitle-2'>
              Tech Solution
            </Text>
            <Text<'h1'> className={styles.name} as='h1' font='title-2'>
              {name}
            </Text>
            <Text className={styles.tagline} font='title-6'>
              {title}
            </Text>
            <Text className={styles.description} font='body-2'>
              {description}
            </Text>

            <ButtonGroup className={styles.actions}>
              <AccessResolver
                accessToken={accessToken}
                techSolutionData={techSolutionData}
                navigate={navigate}
              />
              {devPortalProjectName && (
                <Button
                  as={Link}
                  size='small'
                  variant='secondary'
                  href={`${config.docsUrl}/${devPortalProjectName}`}
                >
                  Documentation
                </Button>
              )}
              <GetSupport
                name={name}
                slack={slack}
                navigate={navigate}
                supportService={supportService}
              />

              <SubscribeButton
                techSolutionId={techSolutionId}
                platformConsoleApiUrl={config.platformConsoleApiUrl}
                accessToken={accessToken}
                name={name}
              />
              <FavoriteStatus
                techSolutionId={techSolutionId}
                platformConsoleApiUrl={config.platformConsoleApiUrl}
                accessToken={accessToken}
                name={name}
              />
              {showAdmin && (
                <Button
                  as={Link}
                  size='small'
                  variant='secondary'
                  navigate={navigate}
                  href={`${window.location.origin}/admin/${slug}`}
                  target='_self'
                >
                  Admin Form
                </Button>
              )}
            </ButtonGroup>

            <HealthStatus
              slug={slug}
              techSolutionName={name}
              navigate={navigate}
              regionalHealth={regionalHealth}
              uptime={uptime}
            />
            {!!techSolutionData?.lifecycle?.status && (
              <LifeCycle status={techSolutionData.lifecycle.status} />
            )}
            <TSPlatforms
              techSolutionId={techSolutionId}
              navigate={navigate}
              accessToken={accessToken}
            />
            {!!techSolutionData?.categories?.length && (
              <Categories categories={techSolutionData.categories} />
            )}
            <hr className={styles.divider} aria-hidden='true' />
            <div>
              <TabsGroup
                navigate={navigate}
                techSolutionName={name}
                relatedTechSolutions={relatedTechSolutions}
                roadmapLink={roadmapLink}
                contacts={contactsFiltered}
                owningTeamName={owningTeamName}
                owningTeam={owningTeam}
                devportalUrl={config.devportalUrl}
                templates={templates}
                hasTemplates={!!techSolutionData.templates?.length}
                trainingResources={techSolutionData.trainingResources}
                releaseNotes={releaseNotes}
                newReleaseNotesCount={userData?.newReleaseNotesCount}
                releaseNotesLastSeen={userData?.releaseNotesLastSeen}
                updateUserReleaseNotesLastSeen={updateUserReleaseNotesLastSeen.bind(
                  null,
                  updateUserReleaseNotesLastSeenArgs
                )}
                metricWidgets={techSolutionData.metricWidgets}
                videos={techSolutionData.videos}
                calendar={calendar}
                accessToken={accessToken}
                showCalendarPoc={showCalendarPoc}
              />
            </div>
          </div>
        </div>
      </Snacks>
    </ApolloProvider>
  )
}

const getTechSolutionCache = (id: string): TechSolutionData => {
  try {
    const data: string | null = localStorage.getItem(`techSolution-${id}`)
    return data ? (JSON.parse(data) as TechSolutionData) : ({} as TechSolutionData)
  } catch (error) {
    console.error('Issue getting  cache: ', (error as Error)?.message || error)
    return {} as TechSolutionData
  }
}

const setTechSolutionCache = (id: string, data: TechSolutionData) => {
  try {
    localStorage.setItem(`techSolution-${id}`, JSON.stringify(data))
  } catch (error: unknown) {
    console.error('Issue setting  cache: ', (error as Error)?.message || error)
  }
}

const HomePageSkeleton = (): JSX.Element => (
  <div className={styles.pageWrapper}>
    <div className={styles.homePage}>
      <Skeleton className='eds-spacing--mb-8' height={16} width={100} />
      <Skeleton className='eds-spacing--mb-8' height={48} width={300} />
      <Skeleton className='eds-spacing--mb-24' height={28} width={400} />
      <Skeleton className='eds-spacing--mb-8' height={24} width={'100%'} />
      <Skeleton className='eds-spacing--mb-8' height={24} width={'100%'} />
      <Skeleton className='eds-spacing--mb-16' height={24} width={'100%'} />
      <Skeleton className='eds-spacing--mb-16' height={60} width={'100%'} />
      <ButtonGroup className={styles.actions}>
        <Skeleton radius={'rounded'} height={40} width={40} />
        <Skeleton height={40} width={200} />
      </ButtonGroup>
      <Skeleton className='eds-spacing--mb-12' height={80} width={270} />
      <hr className={styles.divider} aria-hidden='true' />
      <ButtonGroup className={styles.actions}>
        <Skeleton as='span' height={40} width={100} />
        <Skeleton as='span' height={40} width={100} />
        <Skeleton as='span' height={40} width={100} />
        <Skeleton as='span' height={40} width={100} />
        <Skeleton as='span' height={40} width={100} />
      </ButtonGroup>
      <Skeleton className='eds-spacing--mt-32' height={100} width={'100%'} />
      <ButtonGroup>
        <Skeleton className='eds-spacing--mt-32 eds-spacing--mr-96' height={100} width={'45%'} />
        <Skeleton className='eds-spacing--mt-32' height={100} width={'45%'} />
      </ButtonGroup>
    </div>
  </div>
)
