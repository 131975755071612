import styles from '../homePage.styl'
import { Chip, Label, StatusIndicator } from '@nike/eds'
import { useEffect, useState } from 'react'

interface StatusIndicatorProps {
  status: 'neutral' | 'warning' | 'success' | 'danger' | 'inactive'
  label: string
}

interface LifeCycleProps {
  status: string
}
export const LifeCycle = ({ status }: LifeCycleProps): JSX.Element => {
  const [lifecycle, setLifecycle] = useState<StatusIndicatorProps>({
    status: 'neutral',
    label: 'Unknown',
  })

  useEffect(() => {
    if (status) {
      switch (status) {
        case 'PILOT':
          setLifecycle({ label: 'Pilot', status: 'neutral' })
          break
        case 'PLAN':
          setLifecycle({ label: 'Plan', status: 'warning' })
          break
        case 'PRODUCTION':
          setLifecycle({ label: 'Production', status: 'success' })
          break
        case 'SUNSET':
          setLifecycle({ label: 'Sunset', status: 'warning' })
          break
        case 'RETIRED':
          setLifecycle({ label: 'Retired', status: 'danger' })
          break
        default:
          setLifecycle({ label: 'Unknown', status: 'neutral' })
      }
    }
  }, [status])
  return (
    <div className={styles.tsChipsContainer}>
      <Label font='subtitle-1'>Lifecycle:</Label>
      <Chip size='small' className={styles.edsChip}>
        <StatusIndicator status={lifecycle.status} label={lifecycle.label} />
      </Chip>
    </div>
  )
}
